import {BorderedTextField, TextButton, PhoneNumberTextField} from '@hconnect/uikit'
import {Box, Grid, Typography} from '@material-ui/core'
import {isValidPhoneNumber} from 'libphonenumber-js'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {getUserProfileCountry} from '../../../../UserProfile/UserProfile.selectors'

import testIds from './dataTestIds'
import {MaterialOrderForm} from './types'

const emptyHaulierInfo = {
  driverName: '',
  driverPhoneNumber: '',
  trailerLicensePlate: '',
  truckLicensePlate: ''
} as const

interface HaulierInfoTabProps {
  showHaulierInfoWithoutPhoneNumber?: boolean
}

export const HaulierInfoTab: React.FC<HaulierInfoTabProps> = ({
  showHaulierInfoWithoutPhoneNumber
}) => {
  const methods = useFormContext<MaterialOrderForm>()

  const {t} = useTranslation()
  const MAX_CHARS = 35

  const userProfileCountry = useSelector<AppState, string | null | undefined>((state) =>
    getUserProfileCountry(state)
  )

  const validateInput = (inputValue: string) => {
    const validInputPattern = /["\t\n]/g

    const found = inputValue && inputValue.match(validInputPattern)
    if (found && found.length > 0) return false

    return true
  }

  const removeInvalidCharacters = (inputValue: string) => {
    return inputValue.replace(/\t+/g, ' ').replace(/\s{2,}/g, ' ')
  }

  return (
    <Grid>
      {!showHaulierInfoWithoutPhoneNumber && (
        <Box display="flex" flexDirection="column">
          <Controller
            control={methods.control}
            name="haulerInfo.driverName"
            rules={{
              maxLength: MAX_CHARS,
              validate: (value) => {
                return validateInput(value ?? '')
              }
            }}
            render={({field, fieldState}) => {
              return (
                <>
                  <BorderedTextField
                    data-test-id={testIds.haulier.driverName}
                    label={t('orderIntake.haulerInfo.driverName')}
                    placeholder={t('orderIntake.haulerInfo.driverName')}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      field.onChange(removeInvalidCharacters(e.target.value))
                    }}
                    onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                    inputProps={{maxLength: MAX_CHARS}}
                    error={!!fieldState.error}
                    helperText={`${field?.value?.length ?? 0} / ${MAX_CHARS}`}
                  />
                  {!!fieldState.error && (
                    <Typography variant="caption" component="span" color="error">
                      {t('orderIntake.haulerInfo.invalidCharacters')}
                    </Typography>
                  )}
                </>
              )
            }}
          />
        </Box>
      )}

      {!showHaulierInfoWithoutPhoneNumber && (
        <Box display="flex" flexDirection="column" marginTop={2}>
          <Controller
            control={methods.control}
            name="haulerInfo.driverPhoneNumber"
            rules={{
              validate: (value) => {
                if (value) return isValidPhoneNumber(value ?? '')
                return true
              }
            }}
            render={({field, fieldState}) => {
              return (
                <PhoneNumberTextField
                  bordered
                  id="driverPhoneNumber"
                  fullWidth
                  name="driverPhoneNumber"
                  data-test-id={testIds.haulier.phoneNumber}
                  value={field.value ?? ''}
                  onPhoneChange={(newPhone) =>
                    newPhone !== (field.value ?? '') && field.onChange(newPhone)
                  }
                  options={{
                    initialCountry: field.value ? '' : userProfileCountry ?? ''
                  }}
                  label={t('orderIntake.haulerInfo.driverPhoneNumber')}
                  error={!!fieldState.error}
                />
              )
            }}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column" marginTop={2}>
        <Controller
          control={methods.control}
          name="haulerInfo.truckLicensePlate"
          rules={{
            maxLength: MAX_CHARS,
            validate: (value) => {
              return validateInput(value ?? '')
            }
          }}
          render={({field, fieldState}) => {
            return (
              <>
                <BorderedTextField
                  data-test-id={testIds.haulier.licensePlate}
                  label={t('orderIntake.haulerInfo.truckLicensePlate')}
                  placeholder={t('orderIntake.haulerInfo.truckLicensePlate')}
                  value={field.value ?? ''}
                  onChange={(e) => {
                    field.onChange(removeInvalidCharacters(e.target.value))
                  }}
                  onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                  inputProps={{maxLength: MAX_CHARS}}
                  error={!!fieldState.error}
                  helperText={`${field?.value?.length ?? 0} / ${MAX_CHARS}`}
                />
                {!!fieldState.error && (
                  <Typography variant="caption" component="span" color="error">
                    {t('orderIntake.haulerInfo.invalidCharacters')}
                  </Typography>
                )}
              </>
            )
          }}
        />
      </Box>

      {!showHaulierInfoWithoutPhoneNumber && (
        <Box display="flex" flexDirection="column" marginTop={2}>
          <Controller
            control={methods.control}
            name="haulerInfo.trailerLicensePlate"
            rules={{
              maxLength: MAX_CHARS,
              validate: (value) => {
                return validateInput(value ?? '')
              }
            }}
            render={({field, fieldState}) => {
              return (
                <>
                  <BorderedTextField
                    data-test-id={testIds.haulier.trailerLicensePlate}
                    label={t('orderIntake.haulerInfo.trailerLicensePlate')}
                    placeholder={t('orderIntake.haulerInfo.trailerLicensePlate')}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      field.onChange(removeInvalidCharacters(e.target.value))
                    }}
                    onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                    inputProps={{maxLength: MAX_CHARS}}
                    error={!!fieldState.error}
                    helperText={`${field?.value?.length ?? 0} / ${MAX_CHARS}`}
                  />
                  {!!fieldState.error && (
                    <Typography variant="caption" component="span" color="error">
                      {t('orderIntake.haulerInfo.invalidCharacters')}
                    </Typography>
                  )}
                </>
              )
            }}
          />
        </Box>
      )}

      <Box marginTop={2} textAlign="right">
        <TextButton
          data-test-id={testIds.haulier.clearBtn}
          variant="text"
          onClick={() => {
            methods.setValue('haulerInfo', emptyHaulierInfo, {shouldTouch: true, shouldDirty: true})
          }}
        >
          {t('orderIntake.haulerInfo.clear')}
        </TextButton>
      </Box>
    </Grid>
  )
}
